
@font-face {
  font-family: 'Pixel';
  src: local('Pixel'),url(../../assets/FreePixel.ttf) format('truetype');
}

html,
body {
  min-height: 100%;
  font-family: Pixel;
  background-color: #f5f5f5;
  height: 100vh !important;

  /* background-color: rgb(15, 23, 42); */
  /* color: white; */
}

.bg-image{
  /* background-image: url(); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
}

@media (min-width: 992px) {
  .bg-image {
    /* height:100vh; */
  }
}

.dark {
  background-color: rgb(15, 23, 42);
  color: white;
  min-height: 100vh;
}

.dark-black {
  background-color: black;
  color: white;
  min-height: 100vh;
}

.dark-color {
  background-color: rgb(15, 23, 42);
  color: white;
  border-color: white;
}


.dark-color .card {
  background-color: rgb(15, 23, 42);
  color: white;
}

.h-100vh {
  min-height: 100vh !important;
}

.h-100vh-desktop {
  /* min-height: 100vh !important; */
}

.body-wrapper {
  padding-top: 20px;
}

.classic-container {
  background-color: #f5f5f5;
}

.top-index {
  z-index: 42;
}

.balance-text {
  text-align: center;
}

@media (max-width: 992px) {
  .h-sm-100 {
    height: 100%!important;
  }
}

@media (min-width: 992px) {
  .h-100-desktop {
    height: 100%!important;
  }
  .h-100vh-desktop {
    min-height: 100vh;
  }
}

@media (min-width: 992px) {
  .body-wrapper {
    /* display: flex; */
    /* align-items: center; */
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .logo {
  }
  .form-signin {
    padding: 15px;
  }
  .play {
    width: 80vw;
  }
  .balance-text {
    text-align: right;
  }
  
}

.form-signin {
  width: 100%;
  max-width: 420px;
  margin: auto;
}

.play {
  width: 90vw;
  max-width: 1000px;
  margin: auto;
  z-index: 5;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.logo {
  height: 200px;
  width: 200px;
}

.bountiful-logo {
  max-height: 20vw;
}


@media only screen and (max-width: 600px) {
  .bountiful-logo {
    max-height: 50vw;
  }
}

.bountiful-container {
  width: 100%;
  margin: auto;
}


.faq {
  width: 69vw;
  max-width: 1000px;
  margin: 32px auto;
  z-index: 1;
}

.coin-flipping {
  width: 100%;
}

.coin-flipping-square {
  width: 200px;
  height: 200px;
}

@media only screen and (max-width: 600px) {
  .logo {
    height: 128px;
    width: 128px;
  }
  .faq {
    padding: 0;
    width: 90vw;
  }
}

.accordion-button:focus {
  border-color: transparent;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: #D3D3D3;
}
.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23a3acb9'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.cursor-pointer {
  cursor:pointer;
}

.toggle-button {
  border: solid 3px transparent;
}

.double-button {
}

.double-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  border: solid 0px transparent;
}

.selected {
  border: solid 3px black !important;
}

.dark .selected {
  border: solid 3px white !important;
}

.dark-black .selected {
  border: solid 4px #ffdf58 !important;
}

p {
  white-space: pre-wrap;
}

.text-twitter{
  color:#1DA1F2 !important;
}

.text-discord{
  color:#5865f2 !important;
}


.social-icons {
  position: relative;
  z-index: 2;
}
/* .social-icons .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 4rem;
  width: 4rem;
  border-radius: 100rem;
} */

@media (min-width: 992px) {
  .social-icons {
    position: absolute;
    /* height: 100%; */
    top: 0;
    right: 1.8rem;
    width: auto;
  }
  .social-icons-left {
    position: absolute;
    /* height: 100%; */
    top: 0;
    left: 1.8rem;
    width: auto;
  }
  .social-icons-bottom-left {
    position: fixed;
    /* height: 100%; */
    bottom: 1.8rem;
    left: 1.8rem;
    width: auto;
    z-index: 10;
  }
  .social-icons-bottom-right {
    position: fixed;
    /* height: 100%; */
    bottom: 1.8rem;
    right: 1.8rem;
    width: auto;
    z-index: 10;
  }
}

.toast-header {
  color: black !important;
  background-color: #ffdf58 !important;
}

.toast-container {
  z-index: 1050;
}

.toast-body {
  text-align: left !important;
  background-color: #fafafa;
}

.dark .toast-body {
  background-color: rgb(15, 23, 42);
  color: white;
  border: solid 1px white;
  /* border-top: none; */
}

.dark-black .toast-body {
  background-color: black;
  color: white;
  border: solid 1px white;
  /* border-top: none; */
}

.sol-balance {
  animation: pop 0.3s linear 1;
}

@keyframes pop{
  50%  {transform: scale(1.2);}
}

.pop {
  animation: pop 0.3s linear 1;
}


.leaderboard-list {
  width: 469px;
}

.form-signin2 {
  width: 100%;
  max-width: 600px;
  padding: 15px;
  margin: auto;
}

.shortcut-row {

}
.profile-picture {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.profile-picture-md .image {
  height: 2.4em;
  width: 2.4em;
  object-fit: cover;
}

.profile-picture-sm .image {
  height: 0.6em !important;
  width: 0.6em !important;
  font-size: 0.6rem !important;
}

.image-xs {
  width: 32px;
  height: 32px;
}

.time-in-row {
  position: absolute;
  right: 10px;
  bottom: 0;
}

.time-placeholder {
  height: 1%;
  margin-bottom: 0.5rem;
}

.h-1per {
  height: 1%;
}

.profile-picture .image {
  width: 2rem!important;
  height: 2rem!important;
}

@media (max-width: 576px) {
  .shortcut-row {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }

  .shortcut-row .btn {
    width: 100%;
  }

  .shortcut-row .leaderboard-list {
    width: 100%;
  }
  .toolbar {
    justify-content: center !important;
  }
}

.toolbar {
  justify-content: flex-end;
}

.profile-picture .image-large {
  width: 128px;
  height: 128px;
}

.dark-black .list-group-item:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.dark .list-group-item:hover {
  background-color: rgba(108, 122, 137, 0.95) !important;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.light .list-group-item:hover {
  background-color: rgba(236, 236, 236, 0.95) !important;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}


.list-group-item a {
  text-decoration: none;
  color: black;
}

/* dark black */

/* .dark-black .body-wrapper {
  background-color: black;
} */

.dark-black .classic-container {
  background-color: black;
}

.dark-black .list-group {
  border: 1px solid #dee2e6!important;
}

.dark-black .list-group-item {
  background-color: black;
  color: white;
}


.dark-black .btn-dark {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.dark-black .btn-outline-dark {
  color: #f8f9fa;
  border-color: #f8f9fa;
}


/* end dark black */

/* .dark .body-wrapper {
  background-color: rgb(15, 23, 42);
} */

.dark .classic-container {
  background-color: rgb(15, 23, 42);
}

.dark .list-group-item {
  background-color: rgb(30, 41, 59);
  color: white;
}


.dark .donation-card {
  background-color: rgb(30, 41, 59);
  color: white;
}

.dark .btn-dark {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.dark .btn-outline-dark {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

body.dark .modal-content {
	background-color: #1e2229;
}

body.dark .modal-content .modal-header,
body.dark .modal-content .modal-footer {
	border-color: #424242;
}

body.dark .form-control {
	background-color: #282d36;
	border-color: #282d36;
}

.dark-color .form-control {
  color: white;
  border: solid 1px white;
  background-color: rgb(30, 41, 59);
}

body.dark .card {
  background-color: rgb(15, 23, 42);
  color: white;
  border: solid 1px white;
}

body.dark .form-control {
  color: white;
  border: solid 1px white;
  background-color: rgb(30, 41, 59);
}

body.dark .double-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(255, 255, 255, 0.1), 0 3px 6px rgba(255, 255, 255, 0.08);
}


body.dark .selected {
  border: solid 3px white;
}

body.dark .btn-warning {
  color: black !important;
  background-color: #ffdf58 !important;
}

.imageWrapper {
  position: relative;
  /* width: 300px; */
  /* height: 300px; */
}
.imageWrapper img {
  display: block;
  object-fit: cover;
}
.imageWrapper .cornerLink {
  opacity: 0;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 2px 0px;
  color: #ffffff;
  background: #000000;
  text-decoration: none;
  text-align: center;
  -webkit-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  -o-transition: opacity 500ms;
  transition: opacity 500ms;

}
.imageWrapper:hover .cornerLink {
  opacity: 0.8;
}

.dark a {
  color: white;
}

.dark-black a {
  color: white;
}

.shake-it {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.progress {
  height: 0.5rem;
  background-color: transparent;
}

video.bg-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}

.z-index-10 {
  z-index: 10;
}


.masthead {
  position: relative;
  overflow: hidden;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.masthead:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.masthead .masthead-content {
  position: relative;
  max-width: 40rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.masthead .masthead-content h1, .masthead .masthead-content .h1 {
  font-size: 2.5rem;
}
.masthead .masthead-content p {
  font-size: 1.2rem;
}
.masthead .masthead-content p strong {
  font-weight: 700;
}
.masthead .masthead-content .input-group-newsletter input {
  height: auto;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
}
.masthead .masthead-content .input-group-newsletter button {
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: calc(1rem + 2px);
}

@media (min-width: 992px) {
  .masthead {
    height: 100%;
    width: 100%;
    min-height: 0;
    padding-bottom: 0;
  }
  .masthead:before {
    /* transform: skewX(-9deg);
    transform-origin: top right; */
  }
  .masthead .masthead-content {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .masthead .masthead-content h1, .masthead .masthead-content .h1 {
    font-size: 3.5rem;
  }
  .masthead .masthead-content p {
    font-size: 1.3rem;
  }
}
@media (min-width: 1200px) {
  .masthead {
    width: 100%;
  }
}

.players-card-header {
  background-color: rgba(0, 0, 0, 0.2);
}
.no-decoration {
  text-decoration: none;
  color:inherit;
}

#dropdown-button-dark-example1 {
  margin-left: 0.5rem;
  height: 100%;
  display: flex;
}


#dropdown-button-dark-example1::after {
  display: none !important;
}

.whale-text {
  text-decoration: underline;
  font-size: 17px;
}

.inp {
  border:none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: inherit;
  padding: 5px 10px;
  outline: none;
  background-color: transparent;
  font-size: 32px;
  max-width: 128px;
  text-align: center;
  color: inherit;
}

/* [placeholder]:focus::-webkit-input-placeholder {
  transition: text-indent 0.4s 0.4s ease; 
  text-indent: -100%;
  opacity: 1;
} */

.give-button {
  width: 480px;
  max-width: 100%;
}

.h-1rem {
  height: 1rem;
}

.progress .bg-light {
  color:black;
}

.navbar-brand-disclaimer {
  font-size: 22px;
}


@media (max-width: 1200px) {
  .navbar-brand-disclaimer {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .navbar-brand-disclaimer {
    font-size: 16px;
  }
}

 .dot1 {
  animation: visibility 3s linear infinite;
 }
 
 @keyframes visibility {
  0% {
  opacity: 1;
  }
  65% {
  opacity: 1;
  }
  66% {
  opacity: 0;
  }
  100% {
  opacity: 0;
  }
 }
 
 .dot2 {
  animation: visibility2 3s linear infinite;
 }
 
 @keyframes visibility2 {
  0% {
   opacity: 0;
  }
  21% {
   opacity: 0;
  }
  22% {
   opacity: 1;
  }
  65% {
   opacity: 1;
  }
  66% {
   opacity: 0;
  }
  100% {
   opacity: 0;
  }
 }
 
 .dot3 {
  animation: visibility3 3s linear infinite;
 }
 
 @keyframes visibility3 {
  0% {
   opacity: 0;
  }
  43% {
   opacity: 0;
  }
  44% {
   opacity: 1;
  }
  65% {
   opacity: 1;
  }
  66% {
   opacity: 0;
  }
  100% {
   opacity: 0;
  }
 }

 .cssload-container {
	width: 100%;
	height: 32px;
	text-align: center;
}

:is(.dark, .dark-black) .cssload-zenith { 
	box-shadow: 3px 3px 1px rgb(255,255,255) !important;
}

.cssload-zenith {
	width: 32px;
	height: 32px;
	margin: 0 auto;
	border-radius: 50%;
	border-top-color: transparent;
	border-left-color: transparent;
	border-right-color: transparent;
	box-shadow: 3px 3px 1px rgb(0,0,0);
	animation: cssload-spin 690ms infinite linear;
		-o-animation: cssload-spin 690ms infinite linear;
		-ms-animation: cssload-spin 690ms infinite linear;
		-webkit-animation: cssload-spin 690ms infinite linear;
		-moz-animation: cssload-spin 690ms infinite linear;
}

@keyframes cssload-spin {
	100%{ transform: rotate(360deg); transform: rotate(360deg); }
}

@-o-keyframes cssload-spin {
	100%{ -o-transform: rotate(360deg); transform: rotate(360deg); }
}

@-ms-keyframes cssload-spin {
	100%{ -ms-transform: rotate(360deg); transform: rotate(360deg); }
}

@-webkit-keyframes cssload-spin {
	100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes cssload-spin {
	100%{ -moz-transform: rotate(360deg); transform: rotate(360deg); }
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
 .la-ball-8bits,
 .la-ball-8bits > div {
     position: relative;
     -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
             box-sizing: border-box;
 }
 
 .la-ball-8bits {
     display: block;
     font-size: 0;
     color: #333;
 }
 
 .la-ball-8bits.la-dark {
     color: #333;
 }

:is(.dark, .dark-black) .la-ball-8bits {
  color: #fff;
}
 
 .la-ball-8bits > div {
     display: inline-block;
     float: none;
     background-color: currentColor;
     border: 0 solid currentColor;
 }
 
 .la-ball-8bits {
     width: 12px;
     height: 12px;
 }
 
 .la-ball-8bits > div {
     position: absolute;
     top: 50%;
     left: 50%;
     width: 4px;
     height: 4px;
     border-radius: 0;
     opacity: 0;
     -webkit-transform: translate(100%, 100%);
        -moz-transform: translate(100%, 100%);
         -ms-transform: translate(100%, 100%);
          -o-transform: translate(100%, 100%);
             transform: translate(100%, 100%);
     -webkit-animation: ball-8bits 1s 0s ease infinite;
        -moz-animation: ball-8bits 1s 0s ease infinite;
          -o-animation: ball-8bits 1s 0s ease infinite;
             animation: ball-8bits 1s 0s ease infinite;
 }
 
 .la-ball-8bits > div:nth-child(1) {
     -webkit-animation-delay: -.9375s;
        -moz-animation-delay: -.9375s;
          -o-animation-delay: -.9375s;
             animation-delay: -.9375s;
 }
 
 .la-ball-8bits > div:nth-child(2) {
     -webkit-animation-delay: -.875s;
        -moz-animation-delay: -.875s;
          -o-animation-delay: -.875s;
             animation-delay: -.875s;
 }
 
 .la-ball-8bits > div:nth-child(3) {
     -webkit-animation-delay: -.8125s;
        -moz-animation-delay: -.8125s;
          -o-animation-delay: -.8125s;
             animation-delay: -.8125s;
 }
 
 .la-ball-8bits > div:nth-child(4) {
     -webkit-animation-delay: -.75s;
        -moz-animation-delay: -.75s;
          -o-animation-delay: -.75s;
             animation-delay: -.75s;
 }
 
 .la-ball-8bits > div:nth-child(5) {
     -webkit-animation-delay: -.6875s;
        -moz-animation-delay: -.6875s;
          -o-animation-delay: -.6875s;
             animation-delay: -.6875s;
 }
 
 .la-ball-8bits > div:nth-child(6) {
     -webkit-animation-delay: -.625s;
        -moz-animation-delay: -.625s;
          -o-animation-delay: -.625s;
             animation-delay: -.625s;
 }
 
 .la-ball-8bits > div:nth-child(7) {
     -webkit-animation-delay: -.5625s;
        -moz-animation-delay: -.5625s;
          -o-animation-delay: -.5625s;
             animation-delay: -.5625s;
 }
 
 .la-ball-8bits > div:nth-child(8) {
     -webkit-animation-delay: -.5s;
        -moz-animation-delay: -.5s;
          -o-animation-delay: -.5s;
             animation-delay: -.5s;
 }
 
 .la-ball-8bits > div:nth-child(9) {
     -webkit-animation-delay: -.4375s;
        -moz-animation-delay: -.4375s;
          -o-animation-delay: -.4375s;
             animation-delay: -.4375s;
 }
 
 .la-ball-8bits > div:nth-child(10) {
     -webkit-animation-delay: -.375s;
        -moz-animation-delay: -.375s;
          -o-animation-delay: -.375s;
             animation-delay: -.375s;
 }
 
 .la-ball-8bits > div:nth-child(11) {
     -webkit-animation-delay: -.3125s;
        -moz-animation-delay: -.3125s;
          -o-animation-delay: -.3125s;
             animation-delay: -.3125s;
 }
 
 .la-ball-8bits > div:nth-child(12) {
     -webkit-animation-delay: -.25s;
        -moz-animation-delay: -.25s;
          -o-animation-delay: -.25s;
             animation-delay: -.25s;
 }
 
 .la-ball-8bits > div:nth-child(13) {
     -webkit-animation-delay: -.1875s;
        -moz-animation-delay: -.1875s;
          -o-animation-delay: -.1875s;
             animation-delay: -.1875s;
 }
 
 .la-ball-8bits > div:nth-child(14) {
     -webkit-animation-delay: -.125s;
        -moz-animation-delay: -.125s;
          -o-animation-delay: -.125s;
             animation-delay: -.125s;
 }
 
 .la-ball-8bits > div:nth-child(15) {
     -webkit-animation-delay: -.0625s;
        -moz-animation-delay: -.0625s;
          -o-animation-delay: -.0625s;
             animation-delay: -.0625s;
 }
 
 .la-ball-8bits > div:nth-child(16) {
     -webkit-animation-delay: 0s;
        -moz-animation-delay: 0s;
          -o-animation-delay: 0s;
             animation-delay: 0s;
 }
 
 .la-ball-8bits > div:nth-child(1) {
     top: -100%;
     left: 0;
 }
 
 .la-ball-8bits > div:nth-child(2) {
     top: -100%;
     left: 33.3333333333%;
 }
 
 .la-ball-8bits > div:nth-child(3) {
     top: -66.6666666667%;
     left: 66.6666666667%;
 }
 
 .la-ball-8bits > div:nth-child(4) {
     top: -33.3333333333%;
     left: 100%;
 }
 
 .la-ball-8bits > div:nth-child(5) {
     top: 0;
     left: 100%;
 }
 
 .la-ball-8bits > div:nth-child(6) {
     top: 33.3333333333%;
     left: 100%;
 }
 
 .la-ball-8bits > div:nth-child(7) {
     top: 66.6666666667%;
     left: 66.6666666667%;
 }
 
 .la-ball-8bits > div:nth-child(8) {
     top: 100%;
     left: 33.3333333333%;
 }
 
 .la-ball-8bits > div:nth-child(9) {
     top: 100%;
     left: 0;
 }
 
 .la-ball-8bits > div:nth-child(10) {
     top: 100%;
     left: -33.3333333333%;
 }
 
 .la-ball-8bits > div:nth-child(11) {
     top: 66.6666666667%;
     left: -66.6666666667%;
 }
 
 .la-ball-8bits > div:nth-child(12) {
     top: 33.3333333333%;
     left: -100%;
 }
 
 .la-ball-8bits > div:nth-child(13) {
     top: 0;
     left: -100%;
 }
 
 .la-ball-8bits > div:nth-child(14) {
     top: -33.3333333333%;
     left: -100%;
 }
 
 .la-ball-8bits > div:nth-child(15) {
     top: -66.6666666667%;
     left: -66.6666666667%;
 }
 
 .la-ball-8bits > div:nth-child(16) {
     top: -100%;
     left: -33.3333333333%;
 }
 
 .la-ball-8bits.la-sm {
     width: 6px;
     height: 6px;
 }
 
 .la-ball-8bits.la-sm > div {
     width: 2px;
     height: 2px;
 }
 
 .la-ball-8bits.la-2x {
     width: 24px;
     height: 24px;
 }
 
 .la-ball-8bits.la-2x > div {
     width: 8px;
     height: 8px;
 }
 
 .la-ball-8bits.la-3x {
     width: 36px;
     height: 36px;
 }
 
 .la-ball-8bits.la-3x > div {
     width: 12px;
     height: 12px;
 }
 
 /*
  * Animation
  */
 @-webkit-keyframes ball-8bits {
     0% {
         opacity: 1;
     }
     50% {
         opacity: 1;
     }
     51% {
         opacity: 0;
     }
 }
 @-moz-keyframes ball-8bits {
     0% {
         opacity: 1;
     }
     50% {
         opacity: 1;
     }
     51% {
         opacity: 0;
     }
 }
 @-o-keyframes ball-8bits {
     0% {
         opacity: 1;
     }
     50% {
         opacity: 1;
     }
     51% {
         opacity: 0;
     }
 }
 @keyframes ball-8bits {
     0% {
         opacity: 1;
     }
     50% {
         opacity: 1;
     }
     51% {
         opacity: 0;
     }
 }
 


@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.animate-flicker {
   -webkit-animation: flickerAnimation .5s infinite;
   -moz-animation: flickerAnimation .5s infinite;
   -o-animation: flickerAnimation .5s infinite;
    animation: flickerAnimation .5s infinite;
}


.text-twitter-blue {
  color: #1DA1F2;
}

.bg-twitter-blue {
  background-color: #1DA1F2;
}

.btn-twitter {
  color: #fff;
  background-color: #1DA1F2;
  border-color: #1DA1F2;
}

.btn-twitter:hover {
  color: #fff;
  background-color: #1DA1F2;
  border-color: #1DA1F2;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #000;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

.loader {
	--color: black;
	--size-mid: 6vmin;
	--size-dot: 1.5vmin;
	--size-bar: 0.4vmin;
	--size-square: 3vmin;
	
	display: block;
	position: relative;
	width: 50%;
	display: grid;
	place-items: center;
}

.loader::before,
.loader::after {
	content: '';
	box-sizing: border-box;
	position: absolute;
}

/**
	loader --1
**/
.loader.--1::before {
	width: var(--size-mid);
	height: var(--size-mid);
	border: 4px solid var(--color);
	border-top-color: transparent;
	border-radius: 50%;
	animation: loader-1 1s linear infinite;
}

.loader.--1::after {
	width: calc(var(--size-mid) - 2px);
	height: calc(var(--size-mid) - 2px);
	border: 2px solid transparent;
	border-top-color: var(--color);
	border-radius: 50%;
	animation: loader-1 0.6s linear reverse infinite;
}

@keyframes loader-1 {
	100% {
		transform: rotate(1turn);
	}
}

/**
	loader --2
**/
.loader.--2::before,
.loader.--2::after {
	width: var(--size-dot);
	height: var(--size-dot);
	background-color: var(--color);
	border-radius: 50%;
	opacity: 0;
	animation: loader-2 0.8s cubic-bezier(0.2, 0.32, 0, 0.87) infinite;
}

.loader.--2::after {
	animation-delay: 0.3s;
}

@keyframes loader-2 {
	0%, 80%, 100% {
		opacity: 0;
	}
	
	33% {
		opacity: 1;
	}
	
	0%, 100% {
		transform: translateX(-4vmin);
	}
	
	90% {
		transform: translateX(4vmin);
	}
}

/**
	loader --3
**/
.loader.--3::before,
.loader.--3::after {
	width: var(--size-dot);
	height: var(--size-dot);
	background-color: var(--color);
	border-radius: 50%;
	animation: loader-3 1.2s ease-in-out infinite;
}

.loader.--3::before {
	left: calc(50% - 1.6vmin - var(--size-dot));
}

.loader.--3::after {
	left: calc(50% + 1.6vmin);
	animation-delay: -0.4s;
}

@keyframes loader-3 {
	0%, 100% {
		transform: translateY(-2.6vmin);
	}
	
	44% {
		transform: translateY(2.6vmin);
	}
}

/**
	loader --4
**/
.loader.--4::before {
	height: var(--size-bar);
	width: 6vmin;
	background-color: var(--color);
	animation: loader-4 0.8s cubic-bezier(0, 0, 0.03, 0.9) infinite;
}

@keyframes loader-4 {
	0%, 44%, 88.1%, 100% {
		transform-origin: left;
	}
	
	0%, 100%, 88% {
		transform: scaleX(0);
	}
	
	44.1%, 88% {
		transform-origin: right;
	}
	
	33%, 44% {
		transform: scaleX(1);
	}
}

/**
	loader --5
**/
.loader.--5::before,
.loader.--5::after {
	height: 3vmin;
	width: var(--size-bar);
	background-color: var(--color);
	animation: loader-5 0.6s cubic-bezier(0, 0, 0.03, 0.9) infinite;
}

.loader.--5::before {
	left: calc(50% - 1vmin);
	top: calc(50% - 3vmin);
}

.loader.--5::after {
	left: calc(50% + 1vmin);
	top: calc(50% - 1vmin);
	animation-delay: 0.2s;
}

@keyframes loader-5 {
	0%, 88%, 100% {
		opacity: 0;
	}
	
	0% {
		transform: translateY(-6vmin);
	}
	
	33% {
		opacity: 1;
	}
	
	33%, 88% {
		transform: translateY(3vmin);
	}
}

/**
	loader --6
**/
.loader.--6::before {
	width: var(--size-square);
	height: var(--size-square);
	background-color: var(--color);
	top: calc(50% - var(--size-square));
	left: calc(50% - var(--size-square));
	animation: loader-6 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes loader-6 {
	0%, 100% {
		transform: none;
	}
	
	25% {
		transform: translateX(100%);
	}
	
	50% {
		transform: translateX(100%) translateY(100%);
	}
	
	75% {
		transform: translateY(100%);
	}
}

/**
	loader --7
**/
.loader.--7::before,
.loader.--7::after {
	width: var(--size-square);
	height: var(--size-square);
	background-color: var(--color);
}

.loader.--7::before {
	top: calc(50% - var(--size-square));
	left: calc(50% - var(--size-square));
	animation: loader-6 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

.loader.--7::after {
	top: 50%;
	left: 50%;
	animation: loader-7 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes loader-7 {
	0%, 100% {
		transform: none;
	}
	
	25% {
		transform: translateX(-100%);
	}
	
	50% {
		transform: translateX(-100%) translateY(-100%);
	}
	
	75% {
		transform: translateY(-100%);
	}
}

/**
	loader --8
**/
.loader.--8::before,
.loader.--8::after {
	width: var(--size-dot);
	height: var(--size-dot);
	border-radius: 50%;
	background-color: var(--color);
}

.loader.--8::before {
	top: calc(50% + 4vmin);
	animation: loader-8-1 0.8s cubic-bezier(0.06, 0.01, 0.49, 1.18) infinite;
}

.loader.--8::after {
	opacity: 0;
	top: calc(50% - 2vmin);
	animation: loader-8-2 0.8s cubic-bezier(0.46,-0.1, 0.27, 1.07) 0.2s infinite;
}

@keyframes loader-8-1 {
	0%, 55%, 100% {
		opacity: 0;
	}
	
	0% {
		transform: scale(0.2);
	}
	
	22% {
		opacity: 1;
	}
	
	33%, 55% {
		transform: scale(1) translateY(-6vmin);
	}
}

@keyframes loader-8-2 {
	0%, 100% {
		opacity: 0;
	}
	
	33% {
		opacity: 0.3;
	}
	
	0% {
		transform: scale(0);
	}
	
	100% {
		transform: scale(4);
	}
}

/**
	loader --9
**/
.loader.--9::before,
.loader.--9::after {
	width: var(--size-dot);
	height: var(--size-dot);
	border-radius: 50%;
	background-color: var(--color);
	animation: loader-9 0.42s cubic-bezier(0.39, 0.31, 0, 1.11) infinite;
}

.loader.--9::before {
	left: calc(50% - var(--size-dot) - 1.6vmin);
}

.loader.--9::after {
	left: calc(50% + 1.6vmin);
	animation-delay: 0.12s;
}

@keyframes loader-9 {
	0%, 100% {
		opacity: 0;
	}
	
	0% {
		transform: translate(-4vmin, -4vmin);
	}
	
	66% {
		opacity: 1;
	}
	
	66%, 100% {
		transform: none;
	}
}

.card-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}


.card-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}